//
//** Funnels
//

//== General

@mixin build-funnels() {

  .funnels {
    padding-right: array-get($emma--widget, layout, self, space);

    .loadFunnels {
      padding: 10px;
    }

    .saveFunnels {
      padding: 10px;

      > div {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
      }

      @include desktop {
        button {
          width: 100%;
          display: grid;
          justify-content: center;
        }
      }
    }

    .funnelStep {
      padding: 10px;

      .funnelStep-dragger {
        font-size: 1rem;
        padding: 5px 0;
      }

      .funnelStep-action {
        display: flex;
        align-items: center;

        .funnelStep-menu {
          flex: 0;
          padding-right: 10px;
        }

        .funnelStep-select {
          flex: 1;
          width: 50px;
        }
      }

      .step__actions {
        display: inline-block;
        padding-right: 10px;
        position: relative;

        .step__actions--menu {
          position: absolute;
          top: 1.8rem;
          left: 0;
          z-index: 1000;
          white-space: nowrap;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          li {
            padding: 0;
            display: block;

            div {
              padding: 0.6rem 1.2rem;
              cursor: pointer;
              font-size: 1rem;

              i.la {
                vertical-align: middle;
                top: -0.08rem;
                position: relative;
                margin-right: 5px;
              }
            }
          }
        }

        .step__actions--button {
          border-radius: 100%;
          width: 1.8rem;
          height: 1.8rem;
          line-height: 1.5rem;
          text-align: center;
          padding: 0 !important;
        }
      }
    }
  }

  .funnelsVisor {
    display: flex;
    padding-left: array-get($emma--widget, layout, self, space);

    .funnelsVisorContainer {
      flex: 1;
      align-self: stretch;

      &-selectTest {
        display: flex;
        justify-content: center;
        padding: 10px;
      }

      .funnelsVisorContainer-conversion {
        text-align: center;
        padding: 10px;

        &--number {
          display: block;
          font-size: 3rem;
          font-weight: 600;
        }

        &--descript {
          font-weight: 500;
        }
      }
    }
  }

  @include tablet-and-mobile {

    .funnelsVisor {
      display: block;
      padding-left: 0;
    }
  }
}

@mixin build-funnels-skin($skin) {

  .funnelStep {

    &:not( :first-child ) {
      border-top: 1px solid get-color($skin, panel);
    }

    .funnelStep-dragger {
      color: get-color($skin, muted);
    }

    .step__actions {

      .step__actions--menu {
        background-color: #fff;

        @include shadow(get-shadow($skin));
        @include border-radius(array-get($m--border-radius, dropdown));

        ul {

          li:hover {

            div {
              background: get-color($skin, panel, '-');
              color: get-color($skin, link);
            }
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component General

@include build-funnels();
@include build-funnels-skin(light);
